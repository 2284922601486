@use "sass:math"
@import "scss/default/variables"
@import "mode"
=shadow($shadowlevel)
	@if $shadowlevel == 0
		box-shadow: 0 1px 1px rgba(0,0,0,0.06), 0 1px 1px rgba(0,0,0,0.12)
	@if $shadowlevel == 1
		box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)
	@if $shadowlevel == 2
		box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)
	@if $shadowlevel == 3
		box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)
	@if $shadowlevel == 4
		box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)
	@if $shadowlevel == 5
		box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)
	@if $shadowlevel == left2
		box-shadow: inset 5px 0 7px -5px rgba(0,0,0,0.5), 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)

$marginvalue:12px

.about-us-list
	li
		margin-bottom: math.div($marginvalue,3) * 2

.logo
	max-width: 200px !important

.backto-top
	> div
		bottom: 15px !important
		right: 15px !important

$mapiconsize: 50px

.ts_bg_image_contact
	background-image: url(#{$mode}/assets/images/ts/portland.jpg) !important

.ts_thumb_lower
	margin-top: -50px !important

.ts_main_slider
	min-height: 1800px
	width: 100%

.ts_showyimage
	+shadow(2)
	border-radius: math.div($marginvalue,2)

.designation
	text-align: left !important

.ts_video_subtitle
	text-align: center
	font-style: italic
	font-weight: 300
	font-family: "Poppins",sans-serif
	width: 80%
	padding-top: $marginvalue
	text-align: justify
	text-justify: inter-word
	
.team-static
	position: relative
	display: block
	text-align: center
	
	.thumbnail
		position: relative
		width: 80%
		max-width: 250px
		display: inline-block
		
		> span 
			&:before
				display: block
				position: absolute
				width: 100%
				height: 100%
				left: 0
				top: 0
				background-image: url(#{$mode}/assets/images/pattern-1.png)
				content: ""
				border-top-right-radius: 6px
				
		img
			position: relative
			width: 100%
			height: auto
			left: 0
			top: 0
			
			&:first-child
				z-index: 1
				
			&:last-child
				z-index: 2
				
			
	.designation
		text-align: center !important


.ts_main_slider_video
	padding-top: $marginvalue * 3
	justify-content: center
	
.ts_main_slider_video_column
	align-items: center
	display: flex
	flex-direction: column

.rn-contact-top-area
	.rn-address
		background-color: #f3f3f3
		+shadow(1)
		
.gm-style-pbc, .gm-style-moc
	opacity: 0 !important

.ts_map_icon
	width: 200px
	display: block
	margin-top: math.div(-$mapiconsize,2)
	margin-left: math.div(-$mapiconsize,2)
	display: flex
	flex-direction: column
	height: 95px
	justify-content: space-between
	position: absolute
	
	p
		padding: math.div($marginvalue,2) $marginvalue
		font-size: 24px
		color: white
		background-color: $theme-color-2
		text-align: center
		left: -74px
		position: relative
		border-radius: $mapiconsize
	
	&::before
		content:"⌂"
		font-size:$mapiconsize * 1.2
		line-height: $mapiconsize * .75
		width: $mapiconsize
		height: $mapiconsize
		border: 2px solid $theme-color
		display: block
		background-color: $theme-color
		color: white
		padding-left:5px
		border-radius: math.div($mapiconsize,4)
		
.ts_footer_image
	margin-top: $marginvalue * 2
	display: flex
	justify-content: flex-start
	
	.ts_footer_image img
		text-align: left
		
.footer-style-2 
	> .wrapper
		padding: 0 80px	

.ts_logo_grid
	display: flex
	flex: 1 auto
	width: 100%
	flex-wrap: wrap
	justify-content: space-between
	
	.ts_logo_grid_item
		width: 25%
		max-width: 300px
		min-width: 200px
		margin: $marginvalue

.ts_portfoliospread
	margin-bottom: 20px
	align-self: flex-start
	text-align: center
	
.ts_col_lg_fifth
	position: relative
	width: 100%
	padding-right: 15px
	padding-left: 15px
	
@media (min-width: 992px)
	.ts_col_lg_fifth
		flex: 0 0 20%
		max-width: 20%

.ts_job_listing
	padding-left: $marginvalue !important
	padding-right: $marginvalue !important
	
	.inner
		background-color: #fafafa
		border-radius: math.div($marginvalue,2)
		+shadow(1)
		padding: $marginvalue * 3 !important
		padding-right: $marginvalue * 4 !important
		padding-bottom: $marginvalue * 4 !important
		background-image: url(#{$mode}/assets/images/whitepaper.png)
		background-image-opacity: .5
		
	& + .ts_job_listing
		margin-top: $marginvalue * 2
		
.ts_slider_colorshifter
	color: $theme-color !important
	-webkit-text-fill-color: $theme-color-2 !important
	
.uppercase
	text-transform: uppercase
		
.ts_row_center
	justify-content: center
	
.ts_headline_justify
	p
		text-align: justify
		text-justify: inter-word
		
	.row
		align-items: center

.ts_text_center_force
	text-align: center
	display: flex
	justify-content: center


h1.ts_section_title
	border-bottom: 1px solid #ccc
	padding-bottom: $marginvalue * 2
	
h1.ts_section_title
	margin-bottom: 0 !important
	padding-top: $marginvalue * 4

.ts_list_style
	padding: $marginvalue * 4 0
	
	p
		text-align: justify
		text-justify: inter-word
		
	+ .ts_list_style
		border-top: 2px solid #ddd
		padding-top: $marginvalue * 6
		
		
	h2.title, h1.title
		border-bottom: 1px solid #ccc
		padding-bottom: $marginvalue * 2
	
	h2.title
		margin-bottom: $marginvalue * 3 !important
		
	h1.title
		margin-bottom: 0 !important
		
	&:nth-child(2n)
		background-color: none
		

.demo_color
	a
		color: adjust-hue($theme-color,180deg) !important

.mobileyes
	display: none
	
	img
		max-width: 300px
		padding-bottom: 30px
		
//https://codepen.io/sosuke/pen/Pjoqqp
//use this to adjust the color of the below to match the $theme-color
		
.image_themer
	filter: invert(66%) sepia(71%) saturate(196%) hue-rotate(162deg) brightness(92%) contrast(92%)
		
.row .thumbnail_low
	margin-top: -80px
	
.footer-left
	padding: 160px 30px
	
	.inner
		margin-right: 260px
		&::after
			background-image: url(#{$mode}/assets/images/logo/big-logo.png)
			content: ""
			transform: translateY(-60%)
			width: 250px
			height: 250px
			background-size: contain
			background-repeat: no-repeat
			margin-right: -260px
			
.footer_bottom
	padding: 12px 24px
	color: #bbbbbb
	width: 100%
	text-align: center
	margin: 0
	
	p
		font-size: 14px
		
.footer-link
	
	+ .footer-link
		margin-top: 36px
	
.footer-right .footer-link h4
	margin-bottom: 20px
	
.footer-right
	padding-top: 36px
	padding-bottom: 24px
	
.ts_footer_copyright
	p
		display: inline-block
			
//media queries

@media #{$extra-device}
	.footer-left
		padding: 120px 30px



@media #{$laptop-device}

	.footer-left
		padding: 140px 30px
	
		.inner
			margin-right: 210px
			&::after
				width: 200px
				height: 200px
				margin-right: -210px
				
			h2
				font-size: 60px
				line-height: 71px
				

				
@media #{$lg-layout}
	.ts_video_subtitle
		width: 95%


	.footer-left
		padding: 120px 30px
	
		.inner
			margin-right: 160px
			&::after
				width: 150px
				height: 150px
				margin-right: -160px

	.ts_footer_copyright_spacer:nth-child(6)
		display: none

@media #{$md-layout}, #{$sm-layout}

	.ts_video_subtitle
		width: 95%
		padding-bottom: $marginvalue * 2
				
	.has-droupdown
		display: none

		&.mobileyes
			display: inherit
			
	.mobileyes
		display: inherit
		
	.footer-style-2 
		> .wrapper
			padding-right: 80px	!important
		
		.inner
			display: flex
			justify-content: center
			
	.footermobileimage
		display: flex !important
		justify-content: flex-end
		width: 100%
		
		img
			max-width: 200px
		
	.ts_footer_copyright_spacer
		display: none !important
		
	.ts_footer_copyright p
		min-width: 100%
		
	p.ts_footer_copyright_p_small
		min-width: 30% !important
		
@media #{$md-layout}
	.footermobileimage
		display: none !important
		
	.ts_main_slider
		min-height: 1700px
	
		
@media #{$sm-layout}

	.ts_main_slider
		min-height: 1450px

	.ts_video_subtitle
		width: 100%

	.footer-link 
		.social-share
			justify-content: flex-end !important

	.footer-left
		.inner
			margin-right: 0
			&::after
				content: none !important

	.footer-right
		text-align: right
		
	.footer-left
		.inner
			&::after
				background-image: none
				
	.ts_footer_image
		margin-top: $marginvalue * 2
		display: flex
		justify-content: flex-end
	
	.ts_footer_image img
		text-align: right
	

			
@media #{$large-mobile}

	.container .ts_job_listing
		padding-left: 0 !important
		padding-right: 0 !important
		
		.inner
			background-color: #fafafa
			border-radius: math.div($marginvalue,2)
			+shadow(1)
			padding-bottom: $marginvalue * 4 !important
			background-image: url(#{$mode}/assets/images/whitepaper.png)
			background-image-opacity: .5

		.inner, .row
			padding: $marginvalue !important
			padding-right: $marginvalue !important
			
		& + .ts_job_listing
			margin-top: $marginvalue * 2
			
	p.ts_footer_copyright_p_small
		min-width: 100% !important

	.footer-right, .ts_footer_image img
		text-align: center
		
	.footer-link .social-share, .footermobileimage, .ts_footer_image
		justify-content: center !important

	.ts_main_slider
		min-height: 1500px
		
	.container, .container-sm
		width: 95%
