/*==================
    Portfolio Area 
==================*/
.portfolio {
    position: relative;
    @extend %transition;
    min-height: 600px;
    width: 100%;
    padding-top: 35%;
    
    @media #{$laptop-device} {
        min-height: 500px;
    }
    @media #{$lg-layout} {
        min-height: 500px;
    }
    @media #{$md-layout} {
        min-height: 450px;
    }
    @media #{$sm-layout} {
        min-height: 400px;
    }
    .thumbnail-inner{
        transition: transform .28s ease;
        z-index: 9;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-size: cover;
        background-position: center;
        overflow: hidden;
        border-radius: 5px;
        cursor: pointer;
        &::before{
            background-color: #f6004c;
            background-image: linear-gradient(#f6004c 10%, #000000 100%);
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            width: 100%;
            height : 100%;
            z-index: 5;
            opacity: 0;
            @extend %transition;
        }
    }
    .thumbnail{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-size: cover;
        background-position: center;
        border-radius: 5px;
        background-color: #0a0a0a;
        background-image: url(#{$mode}/assets/images/portfolio/portfolio-1.jpg);
        transform: scale(1.13) translateZ(0);
        backface-visibility: hidden;
        transition: transform .28s ease;
        z-index: 4;
        &.image-1{
            background-image: url(#{$mode}/assets/images/portfolio/portfolio-1.jpg);
        }

        &.image-2{
            background-image: url(#{$mode}/assets/images/portfolio/portfolio-2.jpg);
        }

        &.image-3{
            background-image: url(#{$mode}/assets/images/portfolio/portfolio-3.jpg);
        }

        &.image-4 {
            background-image: url(#{$mode}/assets/images/portfolio/portfolio-4.jpg);
        }

        &::after{
            background-color: rgba(45,45,45,.35);
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            content: ' ';
            z-index: 1;
            display: block;
            border-radius: 5px;
            background-color: rgba(25,25,25,.37);
            @extend %transition;
        }
    }

    .bg-blr-image {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-size: cover;
        background-position: center;
        background-color: #0a0a0a;
        background-image: url(#{$mode}/assets/images/portfolio/portfolio-1.jpg);
        @extend %transition;
        transform: translateY(38px);
        opacity: 0;
        z-index: 1;

        &.image-1{
            background-image: url(#{$mode}/assets/images/portfolio/portfolio-1.jpg);
        }
        &.image-2{
            background-image: url(#{$mode}/assets/images/portfolio/portfolio-2.jpg);
        }
        &.image-3{
            background-image: url(#{$mode}/assets/images/portfolio/portfolio-1.jpg);
        }
        &.image-4 {
            background-image: url(#{$mode}/assets/images/portfolio/portfolio-1.jpg);
        }
    }

    .content {
        position: absolute;
        bottom: 40px;
        left: 40px;
        right: 40px;
        max-width: 100%!important;
        z-index: 10;
        padding: 0;
        @media #{$lg-layout} {
            left: 20px;
            right: 20px;
        }
        @media #{$md-layout} {
            left: 20px;
            right: 20px;
        }
        @media #{$sm-layout} {
            left: 20px;
            right: 20px;
        }
        .inner {
            p {
                color: #c6c9d8;
                font-size: 14px;
            }
            h4 {
                font-size: 24px;
                line-height: 36px;
                a{
                    color: $white2;
                }
            }
            .title {
                font-size: 24px;
                line-height: 36px;
                color: $white2;
            }
            .portfolio-button{
                @extend %transition;
                margin-top: 35px;
                transition: 0.7s;
                @media #{$large-mobile} {
                    margin-top: 25px;
                }
                a {
                    &.rn-btn{
                        color: $white2;
                        border-color: rgba(255,255,255,0.3);
                        &:hover{
                            border-color: $theme-color;
                        }
                    }
                }
            }
        }
    }

    &.portfolio-interior-design {
        .thumbnail {
            &.image-1{
                background-image: url(#{$mode}/assets/images/portfolio/interior/portfolio-1.jpg);
            }
    
            &.image-2{
                background-image: url(#{$mode}/assets/images/portfolio/interior/portfolio-2.jpg);
            }
    
            &.image-3{
                background-image: url(#{$mode}/assets/images/portfolio/interior/portfolio-3.jpg);
            }
    
            &.image-4 {
                background-image: url(#{$mode}/assets/images/portfolio/interior/portfolio-4.jpg);
            }
            &.image-5 {
                background-image: url(#{$mode}/assets/images/portfolio/interior/portfolio-5.jpg);
            }
            &.image-6 {
                background-image: url(#{$mode}/assets/images/portfolio/interior/portfolio-6.jpg);
            }
            &.image-7 {
                background-image: url(#{$mode}/assets/images/portfolio/interior/portfolio-7.jpg);
            }
        }
        .bg-blr-image{
            background-image: url(#{$mode}/assets/images/portfolio/interior/portfolio-1.jpg);
            &.image-1{
                background-image: url(#{$mode}/assets/images/portfolio/interior/portfolio-1.jpg);
            }
    
            &.image-2{
                background-image: url(#{$mode}/assets/images/portfolio/interior/portfolio-2.jpg);
            }
    
            &.image-3{
                background-image: url(#{$mode}/assets/images/portfolio/interior/portfolio-3.jpg);
            }
    
            &.image-4 {
                background-image: url(#{$mode}/assets/images/portfolio/interior/portfolio-4.jpg);
            }
            &.image-5 {
                background-image: url(#{$mode}/assets/images/portfolio/interior/portfolio-5.jpg);
            }
            &.image-6 {
                background-image: url(#{$mode}/assets/images/portfolio/interior/portfolio-6.jpg);
            }
            &.image-7 {
                background-image: url(#{$mode}/assets/images/portfolio/interior/portfolio-7.jpg);
            }
        }
    }

    &.text-center {
        .content {
            position: absolute;
            bottom: 40px;
            left: 40px;
            max-width: 80% !important;
            z-index: 10;
            padding: 0;
            right: 40px;
            margin: 0 auto;
            @media #{$lg-layout} {
                left: 20px;
                right: 20px;
            }
            @media #{$md-layout} {
                left: 15px;
                right: 15px;
            }
            @media #{$sm-layout} {
                left: 10px;
                right: 10px;
            }
        }
    }
    &:hover{
        .thumbnail-inner{
            transform: scale(1.08) translateZ(0);
            &::before{
                opacity: 0.85;
            }
        }
        .thumbnail {
            transform: scale(1) translateZ(0);
            &::after{
                background-color: rgba(25,25,25,.24);
            }
        }
        .bg-blr-image{
            opacity: 0;
            z-index: 9;
        }
        
    }
    &.portfolio-style--2{
        .inner{
            margin: 0 20px;
        }
    }
}


// Minimal Portfolio 

.portfolio-tilthover {
    .portfolio {
        margin-top: 30px;
        @media #{$sm-layout} {
            min-height: 400px;
        }
        @media #{$md-layout} {
            min-height: 400px;
        }
        @media #{$lg-layout} {
            min-height: 400px;
        }
        .thumbnail {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-size: cover;
            background-position: center;
            border-radius: 5px;
            background-color: #0a0a0a;
            background-image: url(#{$mode}/assets/images/portfolio/portfolio-static-01.jpg);
            transform: scale(1.13) translateZ(0);
            backface-visibility: hidden;
            transition: transform .28s ease;
            z-index: 4;
            @media #{$large-mobile} {
                transform: scale(1) translateZ(0);
            }
            &.image-1 {
                background-image: url(#{$mode}/assets/images/portfolio/portfolio-static-01.jpg);
            }
            &.image-2 {
                background-image: url(#{$mode}/assets/images/portfolio/portfolio-static-02.jpg);
            }
            &.image-3 {
                background-image: url(#{$mode}/assets/images/portfolio/portfolio-static-03.jpg);
            }
            &.image-4 {
                background-image: url(#{$mode}/assets/images/portfolio/portfolio-static-04.jpg);
            }
            &.image-5 {
                background-image: url(#{$mode}/assets/images/portfolio/portfolio-static-05.jpg);
            }
            &.image-6 {
                background-image: url(#{$mode}/assets/images/portfolio/portfolio-static-06.jpg);
            }
            &.image-7 {
                background-image: url(#{$mode}/assets/images/portfolio/portfolio-static-07.jpg);
            }
            &.image-8 {
                background-image: url(#{$mode}/assets/images/portfolio/portfolio-static-08.jpg);
            }
            &.image-9 {
                background-image: url(#{$mode}/assets/images/portfolio/portfolio-static-09.jpg);
            }
            &.image-10 {
                background-image: url(#{$mode}/assets/images/portfolio/portfolio-static-10.jpg);
            }
            &::after {
                display: none;
            }
        }
        
        .thumbnail-inner {
            @media #{$large-mobile} {
                transform: scale(1) translateZ(0);
                &::before {
                    opacity: 0.85;
                }
            }
        }
        .bg-blr-image {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-size: cover;
            background-position: center;
            background-color: #0a0a0a;
            background-image: url(#{$mode}/assets/images/portfolio/portfolio-static-01.jpg);
            @extend %transition;
            transform: translateY(38px);
            opacity: 0;
            z-index: 1;
            @media #{$large-mobile} {
                opacity: 0;
                z-index: 9;
            }

            &.image-1 {
                background-image: url(#{$mode}/assets/images/portfolio/portfolio-static-01.jpg);
            }
    
            &.image-2 {
                background-image: url(#{$mode}/assets/images/portfolio/portfolio-static-03.jpg);
            }
    
            &.image-3 {
                background-image: url(#{$mode}/assets/images/portfolio/portfolio-static-03.jpg);
            }
            &.image-4 {
                background-image: url(#{$mode}/assets/images/portfolio/portfolio-static-04.jpg);
            }
            &.image-5 {
                background-image: url(#{$mode}/assets/images/portfolio/portfolio-static-05.jpg);
            }
            &.image-6 {
                background-image: url(#{$mode}/assets/images/portfolio/portfolio-static-06.jpg);
            }
            &.image-7 {
                background-image: url(#{$mode}/assets/images/portfolio/portfolio-static-07.jpg);
            }
            &.image-8 {
                background-image: url(#{$mode}/assets/images/portfolio/portfolio-static-08.jpg);
            }
            &.image-9 {
                background-image: url(#{$mode}/assets/images/portfolio/portfolio-static-09.jpg);
            }
            &.image-10 {
                background-image: url(#{$mode}/assets/images/portfolio/portfolio-static-10.jpg);
            }
        }

        .content {
            opacity: 0;
            @extend %transition;
            @media #{$large-mobile} {
                opacity: 1;
            }
        }
        &:hover {
            .content {
                opacity: 1;
            }
            .thumbnail-inner {
                transform: scale(1) translateZ(0);
                &::before {
                    opacity: 0.85;
                }
            }
            .thumbnail {
                transform: scale(1) translateZ(0);
                &::after {
                    background-color: rgba(25,25,25,.24);
                }
            }
            .bg-blr-image {
                opacity: 0;
                z-index: 9;
            }
        }


        &.designer-project {
            .thumbnail {
                background-image: url(#{$mode}/assets/images/portfolio/dp-portfolio-01.jpg);
                &.image-1 {
                    background-image: url(#{$mode}/assets/images/portfolio/dp-portfolio-01.jpg);
                }
                &.image-2 {
                    background-image: url(#{$mode}/assets/images/portfolio/dp-portfolio-02.jpg);
                }
                &.image-3 {
                    background-image: url(#{$mode}/assets/images/portfolio/dp-portfolio-03.jpg);
                }
                &.image-4 {
                    background-image: url(#{$mode}/assets/images/portfolio/dp-portfolio-04.jpg);
                }
                &.image-5 {
                    background-image: url(#{$mode}/assets/images/portfolio/dp-portfolio-05.jpg);
                }
                &.image-6 {
                    background-image: url(#{$mode}/assets/images/portfolio/dp-portfolio-06.jpg);
                }
                &.image-7 {
                    background-image: url(#{$mode}/assets/images/portfolio/dp-portfolio-07.jpg);
                }
                &.image-8 {
                    background-image: url(#{$mode}/assets/images/portfolio/dp-portfolio-08.jpg);
                }
                &.image-9 {
                    background-image: url(#{$mode}/assets/images/portfolio/dp-portfolio-09.jpg);
                }
                &.image-10 {
                    background-image: url(#{$mode}/assets/images/portfolio/dp-portfolio-10.jpg);
                }
            }

            .bg-blr-image {
                background-image: url(#{$mode}/assets/images/portfolio/dp-portfolio-01.jpg);
    
                &.image-1 {
                    background-image: url(#{$mode}/assets/images/portfolio/dp-portfolio-01.jpg);
                }
                &.image-2 {
                    background-image: url(#{$mode}/assets/images/portfolio/dp-portfolio-02.jpg);
                }
                &.image-3 {
                    background-image: url(#{$mode}/assets/images/portfolio/dp-portfolio-03.jpg);
                }
                &.image-4 {
                    background-image: url(#{$mode}/assets/images/portfolio/dp-portfolio-04.jpg);
                }
                &.image-5 {
                    background-image: url(#{$mode}/assets/images/portfolio/dp-portfolio-05.jpg);
                }
                &.image-6 {
                    background-image: url(#{$mode}/assets/images/portfolio/dp-portfolio-06.jpg);
                }
                &.image-7 {
                    background-image: url(#{$mode}/assets/images/portfolio/dp-portfolio-07.jpg);
                }
                &.image-8 {
                    background-image: url(#{$mode}/assets/images/portfolio/dp-portfolio-08.jpg);
                }
                &.image-9 {
                    background-image: url(#{$mode}/assets/images/portfolio/dp-portfolio-09.jpg);
                }
                &.image-10 {
                    background-image: url(#{$mode}/assets/images/portfolio/dp-portfolio-10.jpg);
                }
            }
        }
    }
}


.portfolio-sacousel-inner {
    .slick-list{
        margin-left: -200px;
        margin-right: -200px;
        @media #{$md-layout} {
            margin-left: 15px;
            margin-right: 15px;
        } 
        @media #{$lg-layout} {
            margin-left: 15px;
            margin-right: 15px;
        } 
        @media #{$sm-layout} {
            margin-left: 10px;
            margin-right: 10px;
        } 
    }
}



.portfolio-sacousel-inner{
    .slick-slide {
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: -10px;

        @media #{$lg-layout} {
            padding-left: 15px;
            padding-right: 15px;
        }

        @media #{$md-layout} {
            padding-left: 15px;
            padding-right: 15px;
        }

        @media #{$sm-layout} {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}

.portfolio-sacousel-inner {
    .slick-list {
        overflow: visible;
    }
}



.portfolio-sacousel-inner ,
.rn-slick-dot {
    .slick-dots {
        bottom: -60px;
        @media #{$large-mobile} {
            bottom: -50px; 
        }
        li{
            width: 30px;
            margin: 0;
            opacity: .35;
            position: relative;
            
            button{
                &::before{
                    display: none;
                }
                &::after{
                    background: #333;
                    width: 100%;
                    display: block;
                    content: '';
                    position: absolute;
                    left: 0;
                    width: 6px;
                    height: 6px;
                    @extend %transition;
                    border-radius: 50%;
                    margin-left: 11px;
                    background-color: transparent;
                    box-shadow: inset 0 0 0 5px #000;
                }
            }
            &.slick-active{
                opacity: 1;
                button{
                    &::after{
                        -webkit-transform: scale(2);
                        transform: scale(2);
                        box-shadow: inset 0 0 0 1px $theme-color;
                        background-color: transparent;
                        background-color: transparent!important;
                    }
                }
            }
        }
    }
    // Dot Light  
    &.dot-light {
        .slick-dots {
            li{
                button {
                    &::after {
                        background: $white2;
                        box-shadow: inset 0 0 0 5px $white2;
                    }
                }
                &.slick-active{
                    opacity: 1;
                    button{
                        &::after{
                            -webkit-transform: scale(2);
                            transform: scale(2);
                            box-shadow: inset 0 0 0 1px $theme-color;
                            background-color: transparent;
                            background-color: transparent!important;
                        }
                    }
                }
            }
        }
    }
}

/* Slick Arrow Button  */

.portfolio-slick-activation{
    position: relative;
    button {
        &.slick-arrow {
            position: absolute;
            z-index: 3;
            background: transparent;
            font-size: 20px;
            width: 253px;
            height: 101%;
            
            @media #{$lg-layout} {
                width: 136px;
            }

            @media #{$md-layout} {
                width: 100px;
            }

            @media #{$sm-layout} {
                width: 100px;
                display: none !important;
            }

            &::before {
                line-height: 1;
                opacity: 0.75;
                font-size: 30px;
                position: relative;
                top: -14px;
                transition: 0.5s;
            }
            &.slick-prev{
                left: 0;
                background: -moz-linear-gradient(left,  rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
                background: -webkit-linear-gradient(left,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);
                background: linear-gradient(to right,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=1 );
                &::before{
                    content: url(#{$mode}/assets/images/icons/slick-arrow-left.png);
                }
                @media #{$md-layout} {
                    display: none !important;
                }
                @media #{$lg-layout} {
                    display: none !important;
                }
                @media #{$laptop-device} {
                    display: none !important;
                }
                
            }
            &.slick-next{
                right: 0;
                background: -moz-linear-gradient(right,  rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
                background: -webkit-linear-gradient(right,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);
                background: linear-gradient(to left,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=1 );
                &::before{
                    content: url(#{$mode}/assets/images/icons/slick-arrow-right.png);
                }
                @media #{$md-layout} {
                    display: none !important;
                }
                @media #{$lg-layout} {
                    display: none !important;
                }
                @media #{$laptop-device} {
                    display: none !important;
                }
            }


            &:hover{
                &.slick-prev{
                    padding-right: 50px;

                    @media #{$md-layout} {
                        padding-right: 10px;
                    }
                    @media #{$sm-layout} {
                        padding-right: 10px;
                    }
                }
                &.slick-next {
                    padding-left: 50px;
                    @media #{$md-layout} {
                        padding-right: 10px;
                    }
                    @media #{$sm-layout} {
                        padding-right: 10px;
                    }
                }
                &::before{
                    opacity: 1;
                }
            }


        }
    }
}

/* Slick Gutter Space  */
.slick-space-gutter--15 {
    margin-left: -15px;
    margin-right: -15px;
    .slick-slide {
        padding-left: 15px;
        padding-right: 15px;
    }
    .portfolio {
        margin: 40px 0;
    }
}

.slickdot--20 {
    .slick-dots { 
        bottom: -20px;
    }
}





/*-----------------------------
Portfolio Style Three  
--------------------------------*/
.portfolio-style--3 {
    position: relative;
    margin-bottom: 40px;
    @extend %transition;
    overflow: hidden;
    border-radius: 6px;
    .thumbnail {
        a {
            display: block;
            position: relative;
            z-index: 1;
            img{
                @extend %transition;
            }
            &::after {
                background: linear-gradient(to bottom, $theme-color 0, #000000 100%);
                position: absolute;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                content: "";
                z-index: 1;
                opacity: 0;
                @extend %transition;
                @media #{$large-mobile} {
                    opacity: 0.85;
                    top: 0;
                }

            }
        }
    }
    .content{
        position: absolute;
        bottom: -92px;
        width: 100%;
        padding: 50px 35px;
        z-index: 3;
        @extend %transition;
        @media #{$large-mobile} {
            bottom: 0;
        }
        @media #{$large-mobile} {
            padding: 30px 20px;
        }
        &::before {
            position: absolute;
            content: "";
            background: linear-gradient(to bottom, rgba(0,0,0,0.0) 0, #000000 70%);
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 1;
            @extend %transition;
            z-index: -1;
            @media #{$large-mobile} {
                opacity: 0;
            }
        }
        p{
            &.portfoliotype{
                font-size: 14px;
                margin-bottom: 5px;
                color: #c6c9d8;
            }
        }
        h4{
            &.title{
                margin-bottom: 0;
                a{
                    color: $white2;
                    font-size: 24px;
                    font-weight: 500;
                    line-height: 36px;
                    @extend %transition;
                }
            }
        }
        .portfolio-btn {
            @extend %transition;
            margin-top: 35px;
            transition: 0.7s;
            opacity: 0;
            visibility: hidden;
            
            @media #{$large-mobile} {
                margin-top: 22px;
            }
            @media #{$large-mobile} {
                opacity: 1;
                visibility: visible;
            }
        }
    }
    &:hover {
        .content {
            bottom: 0;
            &::before{
                opacity: 0;
            }
        }
        .thumbnail {
            a {
                img{
                    transform: scale(1.1);
                }
                &::before{
                    opacity: 0;
                }
                &::after{
                    opacity: 0.85;
                    top: 0;
                }
            }
        }
        .content{
            .portfolio-btn {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

.row--5 {
    .portfolio-style--3 {
        margin-bottom: 10px;
    }
}


/* Portfolio Style Static */
.item-portfolio-static {
    margin-top: 60px;
    @media #{$sm-layout} {
        margin-top: 30px;
    }
    @media #{$md-layout} {
        margin-top: 30px;
    }
    .inner {
        padding-top: 20px;

        p {
            margin-bottom: 5px;
            font-size: 16px;
        }
        h4 {
            a {
                color: $heading-color;
                transition: 0.3s;
                &:hover {
                    color: $theme-color;
                }
            }
        }
    }
    .thumbnail {
        a{
            img {
                @extend %transition;
            }
        }
    }
    &:hover {
        .thumbnail {
            a{
                img {
                    transform: translate3d(0, -10px, 0);
                    box-shadow: 0 50px 80px -10px rgba(0,0,0,0.17);
                }
            }
        }
    }
}

.link-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: block;
    z-index: 9;
}

